/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import HeroContact from 'components/elements/contact/HeroContact'


const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />
      
      <HeroContact fields={acf.banner} />

      <div className="py-lg-5" />
      <div className="py-lg-5" />
      <div className="py-lg-5" />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          image {
            localFile {
              publicURL
            }
          }
          title
          address
          email
          phone
          formTitle: form_title
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
