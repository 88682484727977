/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'
import GravityForm from 'components/shared/GravityForm'

// Images
import LocationIcon from 'img/location-icon.inline.svg'
import Phone from 'img/phone.inline.svg'
import Mail from 'img/mail.inline.svg'


const Hero = styled.div`

  overflow: hidden;

  @media (min-width: 992px) {
    min-height: 250px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }

`

const Content = styled(ParseContent)`

  & h1 {
      font-family: ${props => props.theme.font.family.secondary};
      font-size: ${props => props.theme.font.size['100']};
      line-height: ${props => props.theme.font.size['90']};
      color: ${props => props.theme.color.text.light};
  }

  & p, & a {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.m};
  }
`

const Info = styled.p`
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.m};
  margin-bottom: 0;
`

const ImageBackground = styled(Image)`
  height: 100%;
`

const Fade = styled.div`
  background-color: ${props => props.theme.color.face.main};
  z-index: -1;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;

  ${ImageBackground} {
    height: 100%;
    width: 100%;
    opacity: 0.45;
  }
`

const FormTitle = styled(ParseContent)`
  & h4 {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size['25']};
    line-height: ${props => props.theme.font.size.xl};
    font-weight: 600;
  }
`

const Right = styled.div`
  @media (min-width: 992px){
    padding-top: 7rem;
  }
`

const HeroHome = ({ fields }) => {

  return (
    <Hero className="position-relative">
      <Background className="row">
        <Fade className="col-lg-6 px-0">
          <ImageBackground src={fields.image} aboveFold />
        </Fade>
        <Right className="col-lg-6 px-0 h-100">
          <iframe 
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2444.0207916995655!2d5.36877517766819!3d52.22484005803926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c640b30c40b1b9%3A0x2a08a8d503ee3f41!2sHet%20Steenland%2018%2C%20Bunschoten-Spakenburg!5e0!3m2!1snl!2snl!4v1720097711150!5m2!1snl!2snl" 
            width="100%" 
            height="1440" 
            frameBorder="0" 
            allowFullScreen="" 
            aria-hidden="false" 
            tabIndex="-1" 
          />
        </Right>
      </Background>
      <div className="container py-5">
        <div className="row py-lg-5">
          <div className="col-lg-6 pr-lg-5 pt-5">
            <Content className="mb-4" content={fields.title} />
            <div className="row py-4">
              <div className="col-lg-6 d-flex flex-wrap">
                <LocationIcon />
                <Content content={fields.address} className="pl-2" />
              </div>
              <div className="col-lg-6 d-flex flex-column">
                <Info>
                  <Phone />
                  <a href={`tel:${fields.phone}`} className="ml-2">{fields.phone}</a>
                </Info>
                <Info>
                  <Mail />
                  <a href={`mailto:${fields.email}`} className="ml-2">{fields.email}</a>
                </Info>
              </div>
            </div>
            <FormTitle content={fields.formTitle} className="mb-4" />
            <GravityForm id={1} />
          </div>
        </div>
        
      </div>
    </Hero>
  )
}

export default HeroHome